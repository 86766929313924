import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, Skeleton, Alert, Row, Col, Button } from 'antd';
import { RootState } from '../store/store';

const { Meta } = Card;

const LatestSantas: React.FC = () => {
  const { items, loading, error } = useSelector((state: RootState) => state.objects);

  const randomSantas = useMemo(() => {
    if (items.length <= 3) return items;
    const shuffled = [...items].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  }, [items]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <Skeleton />
      </div>
    );
  }

  if (error) {
    return (
      <Alert
        message="Ошибка"
        description="Не удалось загрузить список Дедов Морозов."
        type="error"
        showIcon
      />
    );
  }

  return (
    <section className="block">
      <h2 className="subtitle">Новые Деды Морозы</h2>
      <Row gutter={[16, 16]} justify="center">
        {randomSantas.map((santa) => (
          <Col key={santa._id} xs={24} sm={12} md={8}>
            <Card
              hoverable
              cover={<img alt={santa.name} src={santa.image} style={{ height: 200, objectFit: 'contain' }} />}
            >
              <Meta
                title={santa.name}
                description={
                  <div style={{textAlign: "center"}}>
                    <p>Город: {santa.city}</p>
                    <p>Цена: {santa.price} ₽</p>
                    <Button
                    color="primary"
                    variant="solid"
                    className="listing_item_button"
                    onClick={() => {
                      if (window.ym) {
                        window.ym(98813178, "reachGoal", "pozvonili");
                      }
                      window.open(`tel:${santa.telephone}`, "_blank");
                    }}
                  >
                    Позвонить
                  </Button>
                  </div>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
    </section>
  );
};

export default LatestSantas;
