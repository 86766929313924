import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, message } from 'antd';
import useRedirectIfAuthenticated from "../hooks/useRedirectIfAuthenticated.js";
import { register } from '../utils/api.ts'

const RegisterForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useRedirectIfAuthenticated("/профиль/");

  const handleRegister = async (values) => {
    setLoading(true);
    try {
      await register(values);
      message.success('Регистрация прошла успешно');
      navigate('/профиль/');
    } catch (error) {
      console.error('Registration error', error);
      message.error('Регистрация не удалась. Повторите попытку.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <title>Регистрация</title>
    <h1 className="title mb-2">Регистрация</h1>
    <Form
      name="register_form"
      onFinish={handleRegister}
      layout="vertical"
      style={{ maxWidth: 400, margin: '0 auto', padding: '20px', border: '1px solid #d9d9d9', borderRadius: '8px' }}
    >
      <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Пожалуйста, введите ваш email!' }]}>
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item label="Username" name="username" rules={[{ required: true, message: 'Пожалуйста, введите ваше имя!' }]}>
        <Input placeholder="Имя" />
      </Form.Item>

      <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}>
        <Input.Password placeholder="Пароль" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          Регистрация
        </Button>
      </Form.Item>
    </Form>
    </>
  );
};

export default RegisterForm;
