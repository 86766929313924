import React, { useEffect, useState } from "react";

const HelpfulTips = () => {
  const [tip, setTip] = useState({ title: "", content: "" });

  useEffect(() => {
    const fetchTip = async () => {
      try {
        const response = await fetch("/api/tip");
        const data = await response.json();
        setTip(data);
      } catch (error) {
        console.error("Ошибка загрузки текста:", error);
      }
    };

    fetchTip();
  }, []);

  if (tip.content === "") {
    return;
  }

  return (
    <section className="block">
      <h2 className="subtitle">{tip.title}</h2>
      <article>
        <p
          dangerouslySetInnerHTML={{ __html: tip.content }}
          className="article_text"
        />
      </article>
    </section>
  );
};

export default HelpfulTips;
